<template>
  <div class="bot">
    <div class="top">
      <!-- <div class="left">
        <img src="@/assets/qixingp/矩形 942小程序二维码.png" alt="" />
        <p>共享展具小程序</p>
      </div> -->
      <div class="right">
        <img src="@/assets/qixingp/矩形 946公众号二维码@2x.png" alt="" />
        <p>七星公众号</p>
      </div>
    </div>
    <div class="bottom">
      <p>公司名称：青岛七星国际会展科技有限公司</p>
      <p>地址：青岛市市北区福州北路159号昕苑丽都</p>
      <p>电话：4009660622</p>
      <p>电话：4009660622</p>
    </div>
  </div>
</template>

<script>
//import x from ''
export default {
  data() {
    return {
      page: 1,
      total: 0,
      tableData: "",
      list: "",
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    toDetail(id) {
      this.$router.push({ path: "/exhibitionDetail_s", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {};
      that.$http
        .postApi("/qx_company_phone", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data;
            console.log("电话", that.tableData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
// 底部
.top {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #252f35;
  padding-top: 1.0625rem;
  padding-bottom: 1.4375rem;
  .left {
    display: inline-block;
    margin-right: 4.8125rem;
    img {
      width: 5.2rem;
      height: 5.2rem;
    }
    p {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .right {
    display: inline-block;
    img {
      width: 5.2rem;
      height: 5.2rem;
    }
    p {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
    }
  }
}
.bottom {
  width: 100%;
  background: #252f35;
  padding-bottom: 1.4375rem;
  p {
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    line-height: 11px;
    margin-left: 2.625rem;
    margin-top: 1.625rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}
</style>
